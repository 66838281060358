var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isEmpty(_vm.accountPayable),
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-card', {
    attrs: {
      "id": "detail-accounts-payable-card"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-between"
  }, [_c('div', [_c('div', [_c('span', {
    staticClass: "text-heading-4"
  }, [_vm._v(_vm._s(_vm.$t('accountsPayable.agCode')) + ":")]), _vm._v(" " + _vm._s(_vm.agencyCode) + " ")]), _c('div', [_c('span', {
    staticClass: "text-heading-4"
  }, [_vm._v(_vm._s(_vm.$t('accountsPayable.agName')) + ":")]), _vm._v(" " + _vm._s(_vm.agencyName) + " ")])])]), _c('AgenciesTopup', {
    attrs: {
      "sender": _vm.senderData,
      "recipient": _vm.recipientData,
      "receivables": _vm.accountPayable.totalReceivables
    },
    on: {
      "show": _vm.handleOpenTopupModal
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_c('app-collapse', {
    attrs: {
      "type": "border"
    }
  }, [_c('app-collapse-item', {
    attrs: {
      "title": "",
      "is-visible": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-heading-3 text-info text-uppercase"
        }, [_vm._v(_vm._s(_vm.$t('accountsPayable.detail.information')))])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.date')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.query.startDate).date) + " - " + _vm._s(_vm.convertISODateTime(_vm.query.endDate).date) + " ")])], 1), _c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.tradeCreditors')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.accountPayable.totalTradeCreditor)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.openingBalance')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.accountPayable.totalOpeningBalance)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.receivables')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.accountPayable.totalReceivables)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.closingBalance')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.accountPayable.totalClosingBalance)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-50"
  }, [_c('b-col', {
    staticClass: "text-heading-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accountsPayable.unpaidCommission')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.accountPayable.totalUnpaidCommission)) + " ")])], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('app-collapse', {
    attrs: {
      "type": "border"
    }
  }, [_c('app-collapse-item', {
    attrs: {
      "title": "",
      "is-visible": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-heading-3 text-info text-uppercase"
        }, [_vm._v(_vm._s(_vm.$t('accountsPayable.detail.transaction')))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "100%"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.accountPayable.items,
      "responsive": "",
      "bordered": "",
      "fields": _vm.tableDetailColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableDetailColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("accountsPayable.detail.columns.".concat(data.label))) + " ")])];
        }
      };
    }), _vm._l(_vm.tableDetailColumns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap",
            class: {
              'text-right': typeof item[column.key] === 'number'
            }
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(_ref2) {
        var issueDate = _ref2.item.issueDate;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(issueDate).timeDate) + " ")];
      }
    }, {
      key: "cell(bookingDate)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.bookingDate).timeDate) + " ")])];
      }
    }, {
      key: "cell(signBook)",
      fn: function fn(_ref4) {
        var signBook = _ref4.item.signBook;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(signBook) + " ")])];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(_ref5) {
        var priceTicket = _ref5.item.priceTicket;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(priceTicket)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref6) {
        var collectionFee = _ref6.item.collectionFee;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(collectionFee)) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tax)) + " ")])];
      }
    }, {
      key: "cell(otherTax)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.otherTax)) + " ")])];
      }
    }, {
      key: "cell(fee)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.fee)) + " ")])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeService)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaid)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaid)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommission)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.unpaidCommission)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.receivables)) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref15) {
        var totalPayment = _ref15.item.totalPayment;
        return [_c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(totalPayment)) + " ")])];
      }
    }, {
      key: "cell(agency)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.agency.agencyCode) + " ")])];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [data.item.booker ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.booker.username) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(issuer)",
      fn: function fn(data) {
        return [data.item.issuer ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.issuer.username) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(feeIn)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(_vm.roleF2 ? item.feeOut : item.feeIn)) + " ")])];
      }
    }, {
      key: "cell(feeOut)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(_vm.roleF2 ? item.feeIn : item.feeOut)) + " ")])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm.roleMama ? _c('div', [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.agency.id === 1000000 ? item.feeService + item.commission : item.feeIn - item.commissionPrepaidOut)) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.receivables - item.tradeCreditors)) + " ")])])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.createdAt).dateTime) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.updatedAt).dateTime) + " ")])];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }